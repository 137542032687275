import { defineStore } from 'pinia'

export const useAppStore = defineStore('app', {
  state: () => ({
    categories: null,
    theme: 'light',
    isShowMainLoader: true,
    direction: 'ltr',
    contactsName: null,
    contactsEmail: null,
    contactsPhone: null,
    contactsMessage: null,
    contactsUrl: null,
    contactsSent: false,
  }),

  actions: {
    async formRequest(data) {
      return await $fetch('/api/sendmail', {
        headers: { 'Content-type': 'application/json' },
        method: 'POST',
        body: data,
      })
    },

    contactsSubmitForm(data) {
      // console.log('from store')
      // console.log({ data })

      // this.formRequest(data)
      //   .then((result) => {
      //     console.log(result)
      //   })
      //   .catch((error) => {
      //     console.error('Contact form could not be send', error)
      //   })

      this.$patch({
        contactsName: null,
        contactsEmail: null,
        contactsPhone: null,
        contactsMessage: null,
        contactsUrl: null,
        contactsSent: true,
      })
    },

    contactsReset(event) {
      this.$patch({
        contactsName: null,
        contactsEmail: null,
        contactsPhone: null,
        contactsMessage: null,
        contactsUrl: null,
        contactsSent: false,
      })
      event.preventDefault()
    },

    toggleTheme(payload = null) {
      payload = payload || this.theme // light|dark
      localStorage.setItem('theme', payload)
      this.theme = payload
      if (this.theme === 'dark') {
        document.querySelector('body')?.classList.add('dark')
      } else {
        document.querySelector('body')?.classList.remove('dark')
      }
    },

    toggleDirection(payload = null) {
      payload = payload || this.direction // rtl, ltr
      localStorage.setItem('direction', payload)
      this.direction = payload
      document
        .querySelector('html')
        ?.setAttribute('dir', this.direction || 'ltr')
    },

    toggleMainLoader(payload = false) {
      this.isShowMainLoader = payload || false
    },
  },
  getters: {},
})
